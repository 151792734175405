* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
  @import "ColorPalette.css";
  
  .basepage {
    display: flex;
    max-height: 100vh;
    max-width: calc(100% - var(--sidebar-width-collapse));
    flex-direction: column;
  }
  
  .flex {
    display: flex;
  }
  
  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 15;
  }
  
  .popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    max-width: 90%; 
    height: 15%;
  }
  
  .cont {
    display: grid;
    grid-row: 1fr 0.5fr;
    flex-direction: column;
    justify-content: center;
    gap:2.5vh ;
    width: 100%;
    background-color: white;
    box-shadow: 0 0.5vw 0.5vw rgba(0, 0, 0, 0.3);
    border-radius: 1vw;
    align-items: center;
    padding-inline: 2%;
    padding-block: 3%;
    grid-area: 2vh;
  }
  
  text1 {
    font-size: 2.7vw;
    color: var(--darkblue);
    text-align: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
  
  .icono {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .icono svg {
    width: 10%; 
    height: auto;
  
  }
  .icono .svg path {
    stroke: var(--pink);
  }