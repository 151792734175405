*{margin: 0;padding: 0;box-sizing: border-box;}
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import "ColorPalette.css";
/*Prueba de tarjetas*/
.stats-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border-radius: 0.5rem;
    border: 1px solid var(--lightgrey);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2%;
    margin: 5%;
    width: 22%;
  
  
    .card-content {
      .card-title {
        font-family: 'Open Sans', sans-serif;
        font-size: 110%;
        font-weight: 700; 
        color:var(--subtitle);
        margin-bottom: 1%;
      }
  
      .card-count {
        font-family: 'Open Sans', sans-serif;
        margin-top: 1%;
        font-size: 200%;
        font-weight: bold;
        color: var(--title);
      }
  
      .rol {
        font-family: 'Open Sans', sans-serif;
        font-size: 100%;
        margin-top: 5%;
        font-weight: 700; 
        color:var(--text);
        margin-bottom: 1%;
      }
    }
  
    .card-icon {
      background-color:white;
      border-radius: 60%;
      padding: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 1.5rem;
      svg {
        width: 5rem;
        height: 5rem;
        color: var(--pink);
      }
    }
  }