.GraficaP {
    display: flex;
    justify-content: center; /* Centrar la gráfica horizontalmente */
    align-items: center; /* Centrar la gráfica verticalmente */
    width: 83vw; /* Ajustar el ancho al 96% para dejar un margen del 2% */
    height: 100%; /* Ajustar la altura al 88vh para dejar un margen del 2% del encabezado */
    transition: 0.3s;
    padding-right: 0.5%;
     /* Añadir margen superior e inferior del 2% y centrar horizontalmente */
    background-color: white;
  }
  
  .Tablero {
    width: 99.5%;
    height: 102%;
    transition: 0.3s;
    border: none;
    overflow: hidden;
    /*background-color: blue; border:1px solid black;*/
    /*border:1px solid black;*/
  }
  
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Estilo global para asegurar que no haya bordes en el componente embebido */
  .GraficaP {
    border: none;
    /*border:1px solid red;*/
  }
  
  .Tablero iframe {
    border: none !important;
  
  }
  
  