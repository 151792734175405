@import "StatsCards.css";
@import "Auth.css";
@import "ContentBase.css";
@import "Graph.css";
@import "Navar.css";
@import "Powerbi.css";

.elementConScroll {
  overflow: hidden;
  width: 100%; 
  height: 100%; 
}

@media (max-width: 600px) {
    .sidebar {
      width: calc(var(--sidebar-width-Expand) - 100px);
      padding: 2%;}
    .Company{font-size: 70%;}
    .Program{font-size: 50%;}
    .sidebar.collapsed ul li a {color:var(--notselected);
      svg {font-size: 110%;color:var(--notselected);}}
    .sidebar.collapsed ul li.active a {padding: 3% 20% 3% 20%;
      svg {font-size: 180%;color:var(--lightblue);}}
      .icono.colapsado {
        width: 100%;
        height: auto; /* Mantener la relación de aspecto */
        margin: 10%;
        padding: 5%;
      }
      
      .icono.expandido {
        width: 80%;
        height: auto; /* Mantener la relación de aspecto */
        margin: 10%;
        padding: 5%;
      }
    .sidebar ul li span.TextOptions {font-size: 60%;}
    .sidebar ul li.active span.TextOptions {font-size: 60%;}

    .sidebar ul li.active a {svg {font-size: 80%;color:var(--lightblue);}}
    .sidebar ul li a {svg {font-size: 80%;color:var(--notselected);}}
    
    /*Responsive sidebar*/
    .sidebar-collapsed .distribution {
      width: calc(var(--sidebar-width-collapse) + 70%);
      margin-left: calc(var(--sidebar-width-collapse) + 8%);
    }
    
    .sidebar-expanded .distribution {
      width: calc(var(--sidebar-width-Expand) + 30%);
      margin-left: calc(var(--sidebar-width-Expand) + 30px);
    }
    
    
  }
  /*Smal screens*/
  @media (max-width: 600px) {
    .sidebar {padding: 2%;}
    .Company{font-size: 70%;}
    .Program{font-size: 50%;}
    .sidebar.collapsed ul li a {color:var(--notselected);
      svg {font-size: 110%;color:var(--notselected);}}
    .sidebar.collapsed ul li.active a {padding: 3% 20% 3% 20%;
      svg {font-size: 180%;color:var(--lightblue);}}
      .icono.colapsado {
        width: 100%;
        height: auto; /* Mantener la relación de aspecto */
        margin: 10%;
        padding: 5%;
      }
      
      .icono.expandido {
        width: 80%;
        height: auto; /* Mantener la relación de aspecto */
        margin: 10%;
        padding: 5%;
      }
    .sidebar ul li span.TextOptions {font-size: 60%;}
    .sidebar ul li.active span.TextOptions {font-size: 60%;}

    .sidebar ul li.active a {svg {font-size: 80%;color:var(--lightblue);}}
    .sidebar ul li a {svg {font-size: 80%;color:var(--notselected);}}
    
    /*Responsive sidebar*/
    .sidebar-collapsed .distribution {
      width: calc(var(--sidebar-width-collapse) + 70%);
      margin-left: calc(var(--sidebar-width-collapse) + 8%);
    }
    
    .sidebar-expanded .distribution {
      width: calc(var(--sidebar-width-Expand) + 30%);
      margin-left: calc(var(--sidebar-width-Expand) + 30px);
    }
    
  }
  
  /*--------------------------------------------Mobiles */
    @media (min-width: 601px) and (max-width: 900px) {
    .sidebar {padding: 2%;}
    .Company{font-size: 90%;}
    .Program{font-size: 70%;}
    .sidebar ul li span.TextOptions {font-size: 80%;}
    .sidebar ul li.active span.TextOptions {font-size: 80%;}
    .sidebar ul li.active a {svg {font-size: 105%;color:var(--lightblue);}}
    .sidebar ul li a {svg {font-size: 105%;color:var(--notselected);}}
  
    .icono.colapsado {
      width: 100%;
      height: auto; 
      margin: 10%;
      padding: 5%;
    }
    
    .icono.expandido {
      width: 70%;
      height: auto; 
      margin: 5%;
      padding: 5%;
    }

    /*Responsive sidebar*/
    .sidebar-collapsed .distribution {
      width: calc(var(--sidebar-width-collapse) + 78%);
      margin-left: calc(var(--sidebar-width-collapse) + 1%);
    }
    
  .sidebar-expanded .distribution {
      width: calc(var(--sidebar-width-Expand) + 36%);
      margin-left: calc(var(--sidebar-width-Expand) + 2%);
    }
    
  }
  
 
    @media (min-width: 901px) and (max-width: 1400px) {
      .RadarContent {
        gap:3%;
      }
    
    .sidebar {padding: 1.5%;}
    .Company{font-size: 110%;}
    .Program{font-size: 80%;}
    .sidebar ul li span.TextOptions {font-size: 80%;}
    .sidebar ul li.active span.TextOptions {font-size: 80%;}
    .sidebar ul li.active a {svg {font-size: 110%;color:var(--lightblue);}}
    .sidebar ul li a {svg {font-size: 110%;color:var(--notselected);}}
    .sidebar.collapsed ul li a {color:var(--notselected);
      svg {font-size: 170%;color:var(--notselected);}}
    .sidebar.collapsed ul li.active a {padding: 5% 20% 5% 20%;
      svg {font-size: 250%;color:var(--lightblue);}}
  
      .icono.colapsado {
        width: 100%;
        height: auto; 
        margin: 10%;
        padding: 5%;
      }
      
      .icono.expandido {
        width: 70%;
        height: auto;
        margin: 5%;
        padding: 5%;
      }

      /*Responsive sidebar*/
    .sidebar-collapsed .distribution {
      width: calc(var(--sidebar-width-collapse) + 78%);
      margin-left: calc(var(--sidebar-width-collapse) + 1%);
    }
    
    .sidebar-expanded .distribution {
        width: calc(var(--sidebar-width-Expand) + 43%);
        margin-left: calc(var(--sidebar-width-Expand) - 6%);
      }
  }
  

  /*----------- Monitor -  Computers*/
/* Estilos base */

  @media (min-width: 1401px) and (max-width: 1440px) {
    .RadarContent {
      gap:3%;
    }
    .sidebar-expanded .distribution {margin-left: calc(var(--sidebar-width-Expand) - 20%);}
    .sidebar-collapsed .distribution {margin-left: calc(var(--sidebar-width-collapse) - 4%);}
    
  
    .sidebar {padding: 0.8%;}
    .Company{font-size: 100%;}
    .Program{font-size: 70%;}
    .sidebar ul li span.TextOptions {font-size: 80%;}
    .sidebar ul li.active span.TextOptions {font-size: 80%;}
  
    .sidebar ul li.active a {svg {font-size: 100%;color:var(--lightblue);}}
    .sidebar ul li a {svg {font-size: 100%;color:var(--notselected);}}
  
    .sidebar.collapsed ul li a {color:var(--notselected);svg {font-size: 150%;
      color:var(--notselected);}}
    .sidebar.collapsed ul li.active a {padding: 5% 20% 5% 20%;
      svg {font-size: 200%;color:var(--lightblue);}}
  
      .icono.colapsado {
        width: 60%;
        height: auto;
        margin: 10%;
        padding: 5%;
      }
      
      .icono.expandido {
        width: 80%;
        height: auto; 
        margin: 5%;
        padding: 5%;
      }
  
      /*Responsive sidebar*/
    .sidebar-collapsed .distribution {
      width: calc(var(--sidebar-width-collapse) + 78%);
      margin-left: calc(var(--sidebar-width-collapse) + 1%);
    }
    .sidebar-expanded .distribution {
        width: calc(var(--sidebar-width-Expand) + 51%);
        margin-left: calc(var(--sidebar-width-Expand) - 12%);
      }
  }


  @media only screen and (min-width: 1441px) and (max-width: 1680px) {
    .RadarContent {
      gap:3%;
    }
    .sidebar-expanded .distribution {margin-left: calc(var(--sidebar-width-Expand) - 20%);}
    .sidebar-collapsed .distribution {margin-left: calc(var(--sidebar-width-collapse) - 4%);}
  
  
    .sidebar {padding: 0.8%;}
    .Company{font-size: 100%;}
    .Program{font-size: 70%;}
    .sidebar ul li span.TextOptions {font-size: 80%;}
    .sidebar ul li.active span.TextOptions {font-size: 80%;}
  
    .sidebar ul li.active a {svg {font-size: 100%;color:var(--lightblue);}}
    .sidebar ul li a {svg {font-size: 100%;color:var(--notselected);}}
  
    .sidebar.collapsed ul li a {color:var(--notselected);svg {font-size: 150%;
      color:var(--notselected);}}
    .sidebar.collapsed ul li.active a {padding: 5% 20% 5% 20%;
      svg {font-size: 200%;color:var(--lightblue);}}
  
      .icono.colapsado {
        width: 60%;
        height: auto;
        margin: 10%;
        padding: 5%;
      }
      
      .icono.expandido {
        width: 80%;
        height: auto; 
        margin: 5%;
        padding: 5%;
      }
  
      /*Responsive sidebar*/
    .sidebar-collapsed .distribution {
      width: calc(var(--sidebar-width-collapse) + 78%);
      margin-left: calc(var(--sidebar-width-collapse) + 1%);
    }
    .sidebar-expanded .distribution {
        width: calc(var(--sidebar-width-Expand) + 52%);
        margin-left: calc(var(--sidebar-width-Expand) - 13%);
      }
  }

  @media only screen and (min-width: 1681px) and (max-width: 1990px) {
    .RadarContent {
      gap:3%;
    }
    .sidebar-expanded .distribution {margin-left: calc(var(--sidebar-width-Expand) - 20%);}
    .sidebar-collapsed .distribution {margin-left: calc(var(--sidebar-width-collapse) - 4%);}
  
  
    .sidebar {padding: 0.8%;}
    .Company{font-size: 100%;}
    .Program{font-size: 70%;}
    .sidebar ul li span.TextOptions {font-size: 80%;}
    .sidebar ul li.active span.TextOptions {font-size: 80%;}
  
    .sidebar ul li.active a {svg {font-size: 100%;color:var(--lightblue);}}
    .sidebar ul li a {svg {font-size: 100%;color:var(--notselected);}}
  
    .sidebar.collapsed ul li a {color:var(--notselected);svg {font-size: 150%;
      color:var(--notselected);}}
    .sidebar.collapsed ul li.active a {padding: 5% 20% 5% 20%;
      svg {font-size: 200%;color:var(--lightblue);}}
  
      .icono.colapsado {
        width: 60%;
        height: auto;
        margin: 10%;
        padding: 5%;
      }
      
      .icono.expandido {
        width: 80%;
        height: auto; 
        margin: 5%;
        padding: 5%;
      }
  
      /*Responsive sidebar*/
    .sidebar-collapsed .distribution {
      width: calc(var(--sidebar-width-collapse) + 80%);
      margin-left: calc(var(--sidebar-width-collapse) - 1%);
    }
    .sidebar-expanded .distribution {
        width: calc(var(--sidebar-width-Expand) + 55%);
        margin-left: calc(var(--sidebar-width-Expand) - 16%);
      }
  }

  @media only screen and (min-width: 1991px) {
    .RadarContent {
      gap:3%;
    }

    .sidebar-expanded .distribution {margin-left: calc(var(--sidebar-width-Expand) - 20%);}
    .sidebar-collapsed .distribution {margin-left: calc(var(--sidebar-width-collapse) - 4%);}
  
  
    .sidebar {padding: 0.8%;}
    .Company{font-size: 100%;}
    .Program{font-size: 70%;}
    .sidebar ul li span.TextOptions {font-size: 80%;}
    .sidebar ul li.active span.TextOptions {font-size: 80%;}
  
    .sidebar ul li.active a {svg {font-size: 100%;color:var(--lightblue);}}
    .sidebar ul li a {svg {font-size: 100%;color:var(--notselected);}}
  
    .sidebar.collapsed ul li a {color:var(--notselected);svg {font-size: 150%;
      color:var(--notselected);}}
    .sidebar.collapsed ul li.active a {padding: 5% 20% 5% 20%;
      svg {font-size: 200%;color:var(--lightblue);}}
  
      .icono.colapsado {
        width: 60%;
        height: auto;
        margin: 10%;
        padding: 5%;
      }
      
      .icono.expandido {
        width: 80%;
        height: auto; 
        margin: 5%;
        padding: 5%;
      }
  
      /*Responsive sidebar*/
    .sidebar-collapsed .distribution {
      width: calc(var(--sidebar-width-collapse) + 80%);
      margin-left: calc(var(--sidebar-width-collapse) - 1%);
    }
    .sidebar-expanded .distribution {
        width: calc(var(--sidebar-width-Expand) + 55%);
        margin-left: calc(var(--sidebar-width-Expand) - 16%);
      }
  }


  /*test*/
  /*Horizontal responsive*/
  @media only screen and (max-height: 360px) and (orientation: landscape){
  .sidebar {padding: 2%;}
  .Nameproduct{margin: 5% 2% 2% 2%;} 
  .Company{font-size: 80%;}
  .Program{font-size: 60%;}
  .sidebar ul li span.TextOptions {font-size: 70%;}
  .sidebar ul li.active span.TextOptions {font-size: 70%;}
  .sidebar ul li a {padding: 4% 0% 4% 5%;margin-top: 10%;
    svg {font-size: 90%;color:var(--notselected);}}
  .sidebar ul li.active a {padding: 4% 0% 4% 5%;
    svg {font-size: 90%;color:var(--lightblue);}}
  .sidebar.collapsed ul li a {color:var(--notselected);
    svg {font-size: 120%;color:var(--notselected);}}
  .sidebar.collapsed ul li.active a {padding: 3% 20% 3% 20%;
    svg {font-size: 190%;color:var(--lightblue);}}
  .mi-clase-icono.colapsado {padding-top: 10%;margin: 2%;}
  .mi-clase-icono.expandido {padding-top: 10%;margin-top: 25%;}
  .menu-icon {font-size: 1rem;}
  }
  
  @media only screen and (min-height: 361px) and (max-height: 640px) and (orientation: landscape){
  .sidebar {padding: 2%;}
  .Nameproduct{margin: 5% 2% 2% 2%;} 
  .Company{font-size: 90%;}
  .Program{font-size: 70%;}
  .sidebar ul li span.TextOptions {font-size: 80%;}
  .sidebar ul li.active span.TextOptions {font-size: 80%;}
  .sidebar ul li a {padding: 4% 0% 4% 5%;margin-top: 10%;
    svg {font-size: 100%;color:var(--notselected);}}
  .sidebar ul li.active a {padding: 4% 0% 4% 5%;
    svg {font-size: 100%;color:var(--lightblue);}}
    
  .sidebar.collapsed ul li a {color:var(--notselected);
    svg {font-size: 120%;color:var(--notselected);}}
  .sidebar.collapsed ul li.active a {padding: 3% 20% 3% 20%;
    svg {font-size: 190%;color:var(--lightblue);}}
  .mi-clase-icono.colapsado {padding-top: 10%;margin: 2%;}
  .mi-clase-icono.expandido {padding-top: 7%;margin-top: 25%;}
  .menu-icon {font-size: 1.1rem;}
  
  }





 
       

