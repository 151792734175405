:root{
    /*Responsive units*/
    --sidebar-width-Expand: 30%;
    --sidebar-width-collapse: 10%;
    --compay-width-Expand:60%;
  
    /*color palette*/
      /*border containers*/
      --border:#D9D9D9;
      --borderC:#F2F2F2;
      --containertB:#DBE5EB;
      --colorTable:#F8F8F8;
      
      /*Main colors*/
      --darkblue:#010630;
      --lightblue:#2B306E;
      --purple:#4A064F;
      --pink:#FF00B1;
      --base:#F2F2F2;
  
    /*text colors*/
  
      /*Main text color*/
      --title:#333232;
      --subtitle:#595959;
      --text:#687079;
  
      /*Blue text color*/
      --lightgrey:#F2F2F2;
      --middlegray:#B6B7CD;
      --Ultralightblue:#ECEDFF;
  
      /*Selected colors*/
      --notselected:#A0A0A0;
      --pinkselected:#c30d8c;
      --Darkpink:#860c61;
      --blue:#3B4191;
      --Lightpink:#FFB0E7;
  }