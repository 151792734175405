*{margin: 0;padding: 0;box-sizing: border-box;}
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import "ColorPalette.css";



  /*Sidebar*/
  .sidebar {
      display: flex;
      z-index: 9999;
      flex-direction: column;
      justify-content: space-between;
      color: white;
      height: 100vh; 
      width:var(--sidebar-width-Expand); 
      position: fixed; 
      padding: 1%;
      max-width: 250px;
      min-width: 150px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, var(--darkblue) 34.83%, var(--purple) 66.83%, var(--pink) 100%);
    }
  

    /* Extend sidebar*/
  .sidebar .menu-item:nth-child(1) { flex: 1 1 10%; }
  .sidebar .menu-item:nth-child(2) { flex: 1 1 30%; }
  .sidebar .menu-item:nth-child(3) { flex: 1 1 10%; }
  .sidebar .menu-item:nth-child(4) { flex: 1 1 10%; }
  .sidebar .menu-item:nth-child(5) { flex: 1 1 10%; }
  .sidebar .menu-item:nth-child(6) 
  { flex: 1 1 60%; 
    display: flex;
    align-items:flex-end;
    justify-content:center;
    overflow: hidden;
  }
  
  .sidebar ul li{
    list-style-type: none;
    align-items: center;  
    padding: 3%;
    transition: background-color 0.2s, padding 0.2s;
  }
  
  .sidebar ul li a {
    color:var(--notselected);
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 4% 0% 4% 5%;
    margin-top: 5%;
    border-radius: 0.4rem;
    transition: background-color 0.2s, padding 0.2s;
    svg {
      font-size: 100%;
      color:var(--notselected);
    }
  }
  
  .sidebar ul li.active a {
    width: 100%;
    height: 100%;
    background-color:var(--pink);
    display: flex;
    align-items: flex;
    padding: 4% 0% 4% 5%;
    transition: background-color 0.2s, padding 0.2s;
    svg {
        font-size: 100%;
        color:var(--lightblue);
      }
  }
  
  .sidebar ul li span.TextOptions {
    color:var(--notselected);
    font-family: "Open Sans";
    font-size: 100%;
    font-style: normal;
    font-weight: 700;
    padding-left: 0.5rem;
  }
  
  .sidebar ul li.active span.TextOptions {
    color: var(--lightgrey);
    font-family: "Open Sans";
    font-size: 100%;
    font-style: normal;
    font-weight: 700;
    padding-left: 0.5rem;
  }
  
  /* Collapsed sidebar*/
  .sidebar.collapsed {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width:var(--sidebar-width-collapse);
    max-width: 150px;
    min-width: 50px;
  }
  
  .sidebar.collapsed .menu-item{
    display: flex;
    justify-content: center;
  }
  
  .sidebar.collapsed .menu-item:nth-child(1) { flex: 1 1 5%; }
  .sidebar.collapsed .menu-item:nth-child(2) { flex: 1 1 5%; }
  .sidebar.collapsed .menu-item:nth-child(3) { flex: 1 1 5%; }
  .sidebar.collapsed .menu-item:nth-child(4) { flex: 1 1 5%; }
  .sidebar.collapsed .menu-item:nth-child(5) { flex: 1 1 5%; }
  .sidebar.collapsed .menu-item:nth-child(6) 
  { flex: 1 1 20%; 
    display: flex;
    align-items:flex-end;
    justify-content:center;
    overflow: hidden;
  }
  
  .sidebar.collapsed ul li{
    list-style-type: none;
    align-items: center; 
    margin: 50%;
  }
  
  .sidebar.collapsed ul li a {
    transition: background-color 0.2s, padding 0.2s;
    color:var(--notselected);
    svg {
      font-size: 130%;
      color:var(--notselected);
    }
  }
  
  .sidebar.collapsed ul li.active a {
    transition: background-color 0.2s, padding 0.2s;
    background-color:var(--pink);
    display: flex;
    align-items: center;
    padding: 5% 20% 5% 20%;
    svg {
        font-size: 210%;
        color:var(--lightblue);
      }
  }
  
  /*Program card - Sidebar*/
    .Nameproduct{
      border-radius: 0.3rem;
      margin: 1%;
      padding: 7%;
      background-color:var(--lightblue);
      font-family: 'Open Sans', sans-serif;
    }
    .Company{
      font-weight: 700; 
      font-size: 100%;
      color:var(--lightgrey)
    }
    .Program{
      padding-top: 1%;
      font-weight: 100;
      font-size: 70%;
      color:var(--middlegray)
    }
  
   /*icons basepage*/
   .icono.colapsado {
    width: 100%;
    height: auto; 
    margin: 10%;
    padding: 5%;
  }
  
  .icono.expandido {
    width: 80%;
    height: auto; 
    margin: 10%;
    padding: 5%;
  }
  
    /*Icon for colpase sidebar*/
    .menu-icon {
      margin-right: 10px;
      font-size: 1.3rem;
      cursor: pointer; 
    }
    
  
  /*responsive content*/
  .datav-container{
    background-color: white;
    overflow: hidden;
  }
  
  .distribution {
    background-color: white;
    margin-top: 0.5%;
    height:100vh;
    overflow: hidden;
     /* Oculta el contenido que se desborde del contenedor */
  }

  .sidebar-collapsed .distribution {
    width: calc(var(--sidebar-width-collapse) + 78%);
    margin-left: calc(var(--sidebar-width-collapse) + 1%);
    transition: margin-left 0.2s ease;
  }
  
  .sidebar-expanded .distribution {
    width: calc(var(--sidebar-width-Expand) + 45%);
    margin-left: calc(var(--sidebar-width-Expand) + 1%);
    transition: margin-left 0.1s ease;
  }

 